import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Gps from "/src/components/img/gps"
import ImgCon from "/src/components/img-con"
import SEO from "/src/components/seo"
import BtnWrap from "/src/components/btn-wrap"

export default () => (
  <Layout>
    <SEO title="Rozdělení geodézie" />
    <h1>Rozdělení geodézie</h1>
    <p>Geodézie má dva základní úkoly. Prvním je určení vzájemné polohy bodů na zemském povrchu (nově i mimo) ve smyslu vodorovném i svislém. Druhým je zobrazení těchto bodů vhodným způsobem v&nbsp;rovině, tj. na plánech, mapách ve spolupráci s&nbsp;kartografií a geoinformatikou.</p>
    <p>Geodézie určuje tvary a prostorové polohy <strong>přirozených</strong> (všechny části fyzického povrchu země) a <strong>umělých předmětů</strong> (stavby všeho druhu, vlastnické a užívací hranice) vzhledem k souřadnicové soustavě geodetického základu nebo v relativním vzájemném vztahu.</p>
    <hr /><h2>Základní členění</h2>
    <p>Geodézie se v&nbsp;základním členění dělí na:</p>
    <ul>
    <li><strong>nižší geodézii</strong></li>
    <li><strong>vyšší geodézii</strong></li>
    </ul>
    <p><strong>Nižší geodézie</strong> je vyměřování malých částí na zemském povrchu, které jsou zejména <strong>v rovině</strong> a nejsou výrazně ovlivněny zakřivením zemského tělesa. Do této kategorie zapadá fotogrammetrie, mapování a dálkový průzkum země a zejména pozemkové úpravy.</p>
    <p><strong>Vyšší geodézii</strong> je naopak zaměřená na zaměřování tvaru a rozměrů zemského tělesa, počítá se zde se <strong>zakřivením Země</strong>. K těmto měření slouží především metody družicové, nivelační, astronomické a triangulační. Dále se tyto data využijí pro tvorbu nivelačních a trigonometrických sítí a pro tvorbu geodetických základů.</p>
    <p>Z pohledu používaných technologií toto dělení již ztrácí smysl, liší se hlavně v užití matematického aparátu při zpracování výsledků – rovina x zakřivený prostor, transformace, zohlednění atmosférických a tíhových vlivů aj.</p>
    <hr /><h2>Oblasti zaměření</h2>
    <p>Z&nbsp;pohledu oblasti zaměření (území), rozlišujeme 3 odvětví geodézie, a to:</p>
    <ul>
    <li><strong>globální geodézie</strong></li>
    <li><strong>regionální geodézie</strong></li>
    <li><strong>technická (praktická, lokální) geodézie</strong></li>
    </ul>
    <p><strong>Globální geodézie</strong> se zabývá určováním tvaru a rozměrů Země (geometrické parametry) a jejího vnějšího tíhového pole (fyzikální parametry) a jejich změn, dále pak vztahem Země k ostatním tělesům v&nbsp;kosmu.</p>
    <p><strong>Regionální geodézie</strong> vytváří geodetické a geofyzikální základy pro potřeby jednotlivých států, skupiny států či kontinentů. Právě za tímto účelem jsou budovány geodetické sítě, ať už polohové, výškové, tíhové a prostorové.</p>
    <p><strong><u>Technická (praktická, lokální) geodézie</u> </strong>řeší teoretické a praktické problémy pro nejrůznější obory a činnosti. Ať už jde o sběr a zpracování dat pro tvorbu map, kartografických produktů a informačních systémů, činnosti při projektování a realizaci staveb, budování, správa a údržba katastru nemovitostí nebo vytváření podkladů a zaměřování aktuálního stavu pro další obory lidské činnosti.</p>
    <hr /><h2>Parametrické dělení geodézie</h2>
    <p>Z&nbsp;hlediska parametrů geodézii dělit na:</p>
    <ul>
    <li><strong>jednorozměrnou</strong></li>
    <li><strong>dvojrozměrnou</strong></li>
    <li><strong>trojrozměrnou</strong></li>
    <li><em>(čtyřrozměrná)</em></li>
    </ul>
    <p><strong>Jednorozměrná geodézie (1D)</strong> sleduje buď výšku, či tíži. <strong>Dvojrozměrná geodézie (2D)</strong> pak polohu (typicky osa x a y) a <strong>trojrozměrná geodézie (3D)</strong> pak logicky prostor. Někdy lze vyčlenit i <em>geodézii čtyřrozměrnou (4D) </em>která může propojit prostor s&nbsp;dynamikou, časem i kinematikou.</p>
    <hr /><h2>Dělení vědecké</h2>
    <p>Při vědeckém dělení geodézie lze vymezit množství odvětví, níže jsou uvedena některá z&nbsp;nich. Jsou zde uvedena i některá odvětví příbuzná, nespadající přímo pod geodézii.</p>
    <p><strong>Teoretická geodézie</strong> se zabývá teoretickými otázkami, někdy je do ní řazena také vyšší geodézie.</p>
    <p><strong>Technická geodézie </strong>naopak zahrnuje metody měření, výpočty a zobrazování malých částí zemského povrchu.</p>
    <p><strong>Geodetická astronomie </strong>určuje zeměpisné souřadnice a azimuty na povrchu Země pro geodetické účely na základě pozorování přirozených kosmických objektů.</p>
    <p><strong>Astrofyzika, </strong>oboro astronomie, se zabývá stavbou, fyzikálními vlastnostmi a chemickým složením kosmických těles.</p>
    <p><strong>Gravimetrie</strong> je součást geofyziky, která se zaobírá tíhovými měřeními, studiem tvaru a rozměrů vnějšího tíhového pole Země.</p>
    <p><strong>Fotogrammetrie</strong> je pak vědní obor zabývající se určováním tvaru, rozměrů a polohy předmětů v prostoru z fotogrammetrických snímků.</p>
    <hr /><h2>Metody zaměření</h2>
    <p>Nejstarší metodou geodetického zaměření je <strong>grafická metoda</strong>, která se dnes již prakticky nepoužívá. Originál mapy se při této metodě vyhotovil v&nbsp;daném měřítku přímo v&nbsp;terénu pomocí grafické konstrukce (typicky měřičským stolkem, protínáním).</p>
    <p>Při <strong>číselném zaměření</strong> se měřičské údaje zaznamenají do zápisníku, záznamníku, náčrtu a zpracování se pak děje v&nbsp;kanceláři. Údaje jsou dnes již typicky uchovány a zpracovány digitálně.</p>
    <p>Během <strong>fotogrammetrického zaměření</strong> dochází k&nbsp;náletu nad terén a měření vlícovacích bodů, poté dochází ke zpracování v&nbsp;kanceláři. Viz předmět Fotogrammetrie.</p>
    <hr /><h2>Rozdělení geodetických prací</h2>
    <p>Nejprve při <strong>přípravných pracích </strong>dochází ke zhodnocení dostupných podkladů, určení nejvhodnějších měřických metod, získání výchozích podkladů a je vypracován ekonomický rozbor.</p>
    <p>Během <strong>práce v&nbsp;terénu</strong> dochází logicky k&nbsp;vlastnímu měření pomocí geodetických přístrojů.</p>
    <p><strong>Výpočetní a zobrazovací práce</strong> pak zahrnují zpracování naměřených výsledků, početní vyrovnání a jejich grafické znázornění.</p>
    <hr />
    <ImgCon><Gps /><div>
    <h2>Studijn&iacute; materi&aacute;ly Základy geodézie a GPS</h2>
    <p>T&eacute;ma <strong>Rozdělení geodézie</strong> je souč&aacute;st&iacute; <Link to="/zaklady-geodezie-gps/">studijn&iacute;ch materi&aacute;lů Základy geodézie a GPS (GNSS)</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předešlé (2. tlačítko) či n&aacute;sleduj&iacute;c&iacute;c&iacute; (3.&nbsp;tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/zaklady-geodezie-gps/"><button>Rozcestník geodézie a GPS</button></Link>
    <Link to="/zaklady-geodezie-gps/dejiny-geodezie/"><button className="inv">&larr; Dějiny geodézie</button></Link>
    <Link to="/zaklady-geodezie-gps/mereni-uhlu-smeru/"><button className="inv">Měření úhlů a směrů &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />

  </Layout>
)
